import * as React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import Layout from "./components/layout";
import NewDocumentWorkflow from "./components/create_document_submission";
import { createClient } from "@supabase/supabase-js";
import Login from "./components/auth/login";
import SignUp from "./components/auth/signUp";
import Logout from "./components/auth/logout";
import { Database } from "./lib/database.types";
import RequireAuth from "./components/auth/auth";
import Dashboard from "./components/dashboard";
import SubmitSubmission from "./components/document_submit_screen";
import ListSubmissions from "./components/listSubmissions";
import { AppUser } from "./repo/user";
import ListTemplates from "./components/admin/listTemplates";
import { useLocalstore } from "./hooks/localstore";
import CreateTemplate from "./components/admin/templateForm";
import ListOrgUsers from "./components/admin/orgUsers";
import ListOrgSubmissions from "./components/admin/submissionList";
import SubmissionAdminView from "./components/admin/submissionDetail";

const {
  REACT_APP_SUPABASE_API_ENDPOINT,
  REACT_APP_SUPABASE_ANON_TOKEN,
} = process.env;

interface CtxData {
  error: string | null;
  user: AppUser | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  setUser: React.Dispatch<React.SetStateAction<AppUser | null>>;
}

export const AppContext = React.createContext<CtxData>({
  error: null,
  setError: (v) => {},
  user: null,
  setUser: (v) => {},
});

const App: React.FC = () => {
  const [error, setError] = React.useState<string | null>(null);
  const [user, setUser] = useLocalstore<AppUser | null>("user");

  const supabase = createClient<Database>(
    REACT_APP_SUPABASE_API_ENDPOINT as string,
    REACT_APP_SUPABASE_ANON_TOKEN as string
  );

  React.useEffect(() => {
    if (error) {
      const interval = setInterval(() => setError(null), 5000);
      return () => clearInterval(interval);
    }
  }, [error]);

  const ctxVal = React.useMemo<CtxData>(
    () => ({ error, user, setError, setUser }),
    [error, user]
  );

  return (
    <AppContext.Provider value={ctxVal}>
      <Routes>
        <Route path="/" element={<Layout error={error} supabase={supabase} />}>
          <Route index element={<Dashboard supabase={supabase} />} />
          <Route
            path="/workflow/edit/:submissionId"
            element={
              <RequireAuth supabase={supabase}>
                <NewDocumentWorkflow supabase={supabase} />
              </RequireAuth>
            }
          />
          <Route
            path="/workflow/preview/:submissionId"
            element={
              <RequireAuth supabase={supabase}>
                <SubmitSubmission supabase={supabase} />
              </RequireAuth>
            }
          />
          <Route
            path="/submissions/my"
            element={
              <RequireAuth supabase={supabase}>
                <ListSubmissions supabase={supabase} />
              </RequireAuth>
            }
          />
          <Route path="/admin">
            <Route
              path="templates"
              element={<ListTemplates supabase={supabase} />}
            />
            <Route
              path="templates/new"
              element={<CreateTemplate supabase={supabase} />}
            />
            <Route
              path="templates/edit/:templateId"
              element={<CreateTemplate supabase={supabase} />}
            />
            <Route
              path="users/manage"
              element={<ListOrgUsers supabase={supabase} />}
            />
            <Route
              path="submissions/list"
              element={<ListOrgSubmissions supabase={supabase} />}
            />
            <Route
              path="submissions/manage/:submissionId"
              element={<SubmissionAdminView supabase={supabase} />}
            />
          </Route>
          <Route path="/login" element={<Login supabase={supabase} />} />
          <Route path="/logout" element={<Logout supabase={supabase} />} />
          <Route path="/signup" element={<SignUp supabase={supabase} />} />
        </Route>
      </Routes>
    </AppContext.Provider>
  );
};

export default App;
