import { SupabaseClient } from "@supabase/supabase-js";
import { OutputData } from "@editorjs/editorjs";
import { AppUser } from "./user";

export const getOrgTemplates = async (supabase: SupabaseClient) => {
  const { data, error } = await supabase
    .from("templates")
    .select(`"id", "title", "created_at"`);
  if (error) {
    return [];
  }

  return data;
};

export const getTemplate = async (supabase: SupabaseClient, tid: string) => {
  const { data, error } = await supabase
    .from("templates")
    .select(`"id", "title", "created_at", "data"`)
    .eq("id", tid);
  if (error) {
    return { data: {}, error };
  }

  return { data: data[0] };
};

export const deleteTemplate = async (supabase: SupabaseClient, id: string) => {
  const { error } = await supabase
    .from("templates")
    .delete()
    .eq("id", id);
  return error ? error : null;
};

export const saveTemplate = async (
  supabase: SupabaseClient,
  user: AppUser,
  title: string,
  o: OutputData
) => {
  const { error } = await supabase
    .from("templates")
    .insert({ title, data: o, template_author: user.id, org: user.org });
  if (error) return new Error("error saving templates");
};

export const updateTemplate = async (
  supabase: SupabaseClient,
  id: string,
  user: AppUser,
  title: string,
  o: OutputData
) => {
  const { error } = await supabase
    .from("templates")
    .update({ title, data: o, template_author: user.id, org: user.org })
    .eq("id", id);
  if (error) return new Error("error updating templates");
};
