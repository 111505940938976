import { SupabaseClient } from "@supabase/supabase-js";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../App";

interface Prop {
  supabase: SupabaseClient;
}

const Logout: React.FC<Prop> = ({ supabase }) => {
  const { setUser } = useContext(AppContext);

  useEffect(() => {
    const logout = async () => {
      await supabase.auth.signOut();
      setUser(null);
    };
    logout();
  });

  return (
    <div className="p-1 alert alert-light" role="alert">
      Logged out! <Link to="/login">Login again</Link>
    </div>
  );
};

export default Logout;
