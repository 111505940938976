import * as React from "react";
import { AppContext } from "../../App";

const RequireAdmin: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const { user } = React.useContext(AppContext);

  React.useEffect(() => {
    if (user) {
      setIsAdmin(user.isAdmin);
    }
  }, [user]);

  return isAdmin ? <>{children}</> : <>You are not Authorized</>;
};

export default RequireAdmin;
