import EditorJS, { OutputData } from "@editorjs/editorjs";
// @ts-ignore
import Header from "@editorjs/header";
// @ts-ignore
import List from "@editorjs/list";
// @ts-ignore
import Paragraph from "@editorjs/paragraph";
// @ts-ignore
import Checklist from "@editorjs/checklist";
// @ts-ignore
import AttachesTool from "@editorjs/attaches";
// @ts-ignore
import Table from "@editorjs/table";

import React, { useEffect, useRef } from "react";
import { useCallback } from "react";
import { Button } from "react-bootstrap";

interface Props {
  templateData?: OutputData;
  saveFn: (o: OutputData) => Promise<boolean | Error>;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  uploadFn: (f: File) => Promise<{ success: number }>;
  showSaveButton?: boolean;
  disableAutoSave?: boolean;
  readonly?: boolean;
}

const Editor: React.FC<Props> = ({
  templateData = { blocks: [] },
  saveFn,
  setError,
  uploadFn,
  showSaveButton = false,
  disableAutoSave = false,
  readonly = false,
}) => {
  const isInstance = useRef<EditorJS | null>(null);

  const initEditor = useCallback(
    (templateData: OutputData) => {
      const editor = new EditorJS({
        holder: "editorjsId",
        autofocus: true,
        placeholder: "data here...",
        readOnly: readonly,
        onReady: () => {
          isInstance.current = editor;
        },
        tools: {
          header: {
            class: Header,
            config: {
              levels: [2, 3, 4],
              defaultLevel: 3,
            },
            inlineToolbar: ["link"],
          },
          list: {
            class: List,
            inlineToolbar: true,
            config: { defaultStyle: "unordered" },
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },
          attaches: {
            class: AttachesTool,
            config: {
              uploader: {
                uploadByFile(file: File) {
                  return uploadFn(file);
                },
              },
            },
          },
          table: {
            class: Table,
            inlineToolbar: true,
            config: {
              rows: 3,
              cols: 2,
            },
          },
        },
        data: templateData,
      });
    },
    [isInstance]
  );

  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (!isInstance.current && firstRenderRef.current) {
      firstRenderRef.current = false;
      initEditor(templateData);
    }

    if (!disableAutoSave) {
      const interval = setInterval(() => saveChanges(), 2000);
      return () => {
        isInstance.current && isInstance.current.destroy();
        isInstance.current = null;
        clearInterval(interval);
      };
    } else {
      return () => {
        isInstance.current && isInstance.current.destroy();
        isInstance.current = null;
      };
    }
  }, [isInstance, initEditor, templateData]);

  const saveChanges = async () => {
    const editor = isInstance.current;
    if (editor) {
      const output = await editor.save();
      const e = await saveFn(output);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  return (
    <>
      <div id="editorjsId" className="shadow p-3 mb-5 bg-body rounded"></div>
      {showSaveButton && <Button onClick={saveChanges}>Save</Button>}
    </>
  );
};

export default Editor;
