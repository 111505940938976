import { Session, SupabaseClient } from "@supabase/supabase-js";
import Login from "./login";
import React, { useState, useEffect } from "react";

interface Props {
  children: React.ReactElement;
  supabase: SupabaseClient;
}

const RequireAuth: React.FC<Props> = ({ children, supabase }) => {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return session ? <>{children}</> : <Login supabase={supabase} />;
};

export default RequireAuth;
