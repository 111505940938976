import React, { useContext, useEffect, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import fileIcon from "../assets/icons/file-64.png";
import addFileIcon from "../assets/icons/add-doc-94.png";
import { SupabaseClient } from "@supabase/supabase-js";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../App";

interface Props {
  supabase: SupabaseClient;
}

const Dashboard: React.FC<Props> = ({ supabase }) => {
  const [templates, setTemplates] = useState<{ [x: string]: any }[]>([]);
  const [submissions, setSubmissions] = useState<{ [x: string]: any }[]>([]);
  const { user, setError } = useContext(AppContext);
  const navigate = useNavigate();

  const getTemplates = async () => {
    const { data, error } = await supabase
      .from("templates")
      .select('"id", "title", "type"');
    if (error) {
      setError(error.message);
    } else {
      setTemplates(data);
    }
  };

  const getSubmissions = async () => {
    const { data, error } = await supabase
      .from("submissions")
      .select(`"id", "title", "updated_at"`)
      .order("updated_at", { ascending: false })
      .limit(5);
    if (error) {
      setError(error.message);
    } else {
      setSubmissions(data);
    }
  };

  useEffect(() => {
    if (user) {
      getTemplates();
      getSubmissions();
    } else {
      navigate("/login");
    }
  }, [user]);

  const createNewSubmission = async (templateId: number) => {
    const { error, data } = await supabase.rpc(
      "create_doc_submission_from_template",
      {
        name: "Untitled",
        templateid: templateId,
      }
    );

    if (error) {
      setError("Something went wrong in our side.");
    } else {
      navigate(`/workflow/edit/${data.id}`);
    }
  };

  const editSubmission = async (submissionId: string) => {
    navigate(`/workflow/edit/${submissionId}`);
  };

  const relTime = (t: string): string => {
    const date = new Date();
    const timestamp = date.getTime();
    const seconds = Math.floor(timestamp / 1000);
    const ts = Date.parse(t);
    const difference = seconds - ts / 1000;
    if (difference < 60) {
      // Less than a minute has passed:
      return `Updated moments ago`;
    } else if (difference < 3600) {
      // Less than an hour has passed:
      return `${Math.floor(difference / 60)} minutes ago`;
    } else if (difference < 86400) {
      // Less than a day has passed:
      return `${Math.floor(difference / 3600)} hours ago`;
    } else if (difference < 2620800) {
      // Less than a month has passed:
      return `${Math.floor(difference / 86400)} days ago`;
    }

    return "while ago";
  };

  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>New</Accordion.Header>
          <Accordion.Body>
            <Row className="row-cols-1 row-cols-md-4 g-4">
              {templates.map((t, i) => {
                return (
                  <Col key={i}>
                    <div className="card" style={{ width: "18rem" }}>
                      <img
                        src={addFileIcon}
                        className="card-img-top"
                        alt="document"
                        style={{ width: "64px" }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{t.title}</h5>
                        <p className="card-text">create from template</p>
                        <a
                          href="#"
                          className="stretched-link"
                          onClick={() => createNewSubmission(t.id)}
                        ></a>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Recent</Accordion.Header>
          <Accordion.Body>
            <Row className="row-cols-1 row-cols-md-5 g-4">
              {submissions.map((t, i) => {
                return (
                  <Col key={i}>
                    <div className="card text-center">
                      <img
                        src={fileIcon}
                        className="card-img-top"
                        alt="document"
                        style={{ width: "64px" }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{t.title}</h5>
                        <p className="card-text">
                          <small className="text-muted"></small>
                        </p>
                        <a
                          href="#"
                          className="stretched-link"
                          onClick={() => editSubmission(t.id)}
                        ></a>
                      </div>
                      <div className="card-footer">
                        <small className="text-body-secondary">
                          {relTime(t.updated_at)}
                        </small>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default Dashboard;
