import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Navigation from "./nav";
import { SupabaseClient } from "@supabase/supabase-js";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

interface Prop {
  error: string | null;
  supabase: SupabaseClient;
}

const Layout: React.FC<Prop> = ({ error, supabase }) => {
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    if (error) setShow(true);
  }, [error]);

  return (
    <>
      {error && (
        <div aria-live="polite" aria-atomic="true">
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              zIndex: 9999,
              float: "right",
            }}
          >
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              autohide
            >
              <Toast.Header closeButton={false}>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Bootstrap</strong>
                <small>11 mins ago</small>
              </Toast.Header>
              <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
            </Toast>
          </div>
        </div>
      )}
      <main>
        <div className="col-lg-8 mx-auto p-4 py-md-5">
          <Navigation supabase={supabase} />
          <br />
          {error && (
            <>
              <Alert key="danger" variant="danger">
                {error}
              </Alert>
            </>
          )}
          <Outlet />
          <footer className="pt-5 my-5 text-muted border-top">
            Crafted with love from Coimbatore &middot; &copy; 2023
          </footer>
        </div>
      </main>
    </>
  );
};

export default Layout;
