import { FileObject } from "@supabase/storage-js";
import { SupabaseClient } from "@supabase/supabase-js";
import React, { useContext, useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { AppContext } from "../App";

const ListAttachments: React.FC<{
  supabase: SupabaseClient;
  submissionId: string;
}> = ({ supabase, submissionId }) => {
  const [files, setFiles] = useState<FileObject[]>();
  const { setError } = useContext(AppContext);

  const loadAttachments = async () => {
    const { data, error } = await supabase.storage
      .from("attachments")
      .list(`${submissionId}`);
    if (error) {
      setError(error.message);
    } else {
      setFiles(data);
    }
  };

  useEffect(() => {
    loadAttachments();
  }, []);

  return files ? (
    <div className="mt-2">
      <ListGroup>
        {files &&
          files.map((f, i) => {
            return (
              <ListGroup.Item action key={i}>
                {f.name}
              </ListGroup.Item>
            );
          })}
      </ListGroup>
    </div>
  ) : (
    <>No attachments</>
  );
};

export default ListAttachments;
