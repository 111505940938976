import { SupabaseClient } from "@supabase/supabase-js";
import * as React from "react";
import { AppContext } from "../../App";
import { Button, Form } from "react-bootstrap";
import EditorJS, { OutputData } from "@editorjs/editorjs";
import {
  getTemplate,
  saveTemplate,
  updateTemplate,
} from "../../repo/templates";
import { EditorTools } from "../../lib/editor";
import { useParams } from "react-router-dom";

const CreateTemplate: React.FC<{ supabase: SupabaseClient }> = ({
  supabase,
}) => {
  const { templateId } = useParams();
  const [saving, setSaving] = React.useState<boolean>(false);
  const { setError, user } = React.useContext(AppContext);
  const [title, setTitle] = React.useState<string>("");

  const loadTemplate = async (templateId: string) => {
    const { data, error } = await getTemplate(supabase, templateId);
    if (error) {
      setError(error.message);
      return;
    }

    return data;
  };

  const isInstance = React.useRef<EditorJS | null>(null);
  const firstRenderRef = React.useRef(true);

  const newEditor = React.useCallback(
    (template?: OutputData) => {
      const editor = new EditorJS({
        holder: "editor-js",
        tools: EditorTools((_: File) => {
          return Promise.resolve({ success: 0 });
        }),
        autofocus: true,
        placeholder: "type here...",
        readOnly: false,
        onReady: () => {
          isInstance.current = editor;
        },
        data: template,
      });
    },
    [isInstance]
  );

  const initEditor = async () => {
    if (templateId) {
      const r = await loadTemplate(templateId);
      if (r) {
        setTitle(r.title);
        newEditor(r.data);
        return;
      }
    }
    newEditor();
  };

  React.useEffect(() => {
    if (!isInstance.current && firstRenderRef.current) {
      firstRenderRef.current = false;

      initEditor();
    }
    return () => {
      isInstance.current && isInstance.current.destroy();
      isInstance.current = null;
    };
  }, [isInstance]);

  const handleSave = async () => {
    setSaving(true);
    if (!user) {
      setError("user is misconfigured");
      return false;
    }
    if (!isInstance.current) {
      setError("error loading editor");
      return;
    }

    const output = await isInstance.current.save();
    let error: Error | undefined;
    if (templateId) {
      error = await updateTemplate(supabase, templateId, user, title, output);
    } else {
      error = await saveTemplate(supabase, user, title, output);
    }
    setSaving(false);
    if (error) setError(error.message);
  };

  return (
    <>
      <div className="w-75 mx-auto">
        <h5>{templateId ? "Update Template" : "Create new Template"}</h5>

        <Form.Group className="mb-3">
          <Form.Control
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            size="lg"
            placeholder="Title"
          />
        </Form.Group>
        <div id="editor-js" className="shadow p-3 mb-5 bg-body rounded"></div>

        <Button onClick={handleSave} disabled={saving}>
          {saving ? "Saving" : "Save"}
        </Button>
      </div>
    </>
  );
};

export default CreateTemplate;
