import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import { SupabaseClient } from "@supabase/supabase-js";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";

interface Props {
  supabase: SupabaseClient;
}

const SignUp: React.FC<Props> = ({ supabase }) => {
  const [email, setEmail] = useState<string>("");
  const [fullname, setFullname] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { setError } = useContext(AppContext);
  const navigate = useNavigate();

  const handleSubmit = async (
    e: React.SyntheticEvent<HTMLButtonElement | Event>
  ) => {
    e.preventDefault();
    const { error: createError, data } = await supabase.auth.signUp({
      email,
      password,
    });

    if (createError) {
      setError(createError.message);
    } else {
      const { user } = data;

      if (user) {
        const { error } = await supabase.rpc("set_user_org", {
          fullname,
          email,
        });
        if (error) setError(error.message);
        else navigate("/login");
      }
    }
  };

  return (
    <>
      <main className="w-50 mx-auto mb-5">
        <h4 className="h3 mb-3 fw-normal">SignUp</h4>
        <Form.Group className="mb-3">
          <div className="form-floating">
            <Form.Control
              type="text"
              className="form-control"
              id="fullname"
              placeholder="Fullname"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
            <Form.Label>Full Name</Form.Label>
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <div className="form-floating">
            <Form.Control
              type="email"
              className="form-control"
              id="email"
              placeholder="name@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Text className="text-muted">
              Your email is used for authentication and password recovery and
              notifications.
            </Form.Text>
            <Form.Label>Email</Form.Label>
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <div className="form-floating">
            <Form.Control
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Label>Password</Form.Label>
          </div>
        </Form.Group>

        <button
          className="w-100 btn btn-lg btn-primary"
          type="submit"
          onClick={handleSubmit}
        >
          Sign Up
        </button>
        <div className="p-1 alert alert-light" role="alert">
          Already registered? <Link to="/login">Login</Link>
        </div>
      </main>
    </>
  );
};

export default SignUp;
