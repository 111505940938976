import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { SupabaseClient } from "@supabase/supabase-js";
import { AppContext } from "../../App";
import { getUser } from "../../repo/user";

interface Props {
  supabase: SupabaseClient;
}

const Login: React.FC<Props> = ({ supabase }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { setError, setUser } = useContext(AppContext);
  const navigate = useNavigate();

  const loadUser = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (data.session && !error) {
      const u = await getUser(supabase);
      if ("id" in u) {
        setUser(u);
      } else {
        setError("error getting user");
      }
    }
  };

  const handleSubmit = async (
    e: React.SyntheticEvent<HTMLButtonElement | Event>
  ) => {
    e.preventDefault();
    const resp = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (resp.error) {
      setError(resp.error.message);
    } else {
      await loadUser();
      navigate("/");
    }
  };

  return (
    <div>
      <main className="form-signin w-100 m-auto">
        <Form.Group>
          <h4 className="h3 mb-3 fw-normal">Please sign in</h4>

          <div className="form-floating">
            <Form.Control
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Label>Email address</Form.Label>
          </div>
          <div className="form-floating">
            <Form.Control
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Label>Password</Form.Label>
          </div>

          <button
            className="w-100 btn btn-lg btn-primary"
            type="submit"
            onClick={handleSubmit}
          >
            Sign in
          </button>
        </Form.Group>
        <div className="p-1 alert alert-light" role="alert">
          New? <Link to="/signup">Sign up</Link>
        </div>
      </main>
    </div>
  );
};

export default Login;
