import EditorJS, { OutputData } from "@editorjs/editorjs";

// @ts-ignore
import Header from "@editorjs/header";
// @ts-ignore
import List from "@editorjs/list";
// @ts-ignore
import Paragraph from "@editorjs/paragraph";
// @ts-ignore
import Checklist from "@editorjs/checklist";
// @ts-ignore
import AttachesTool from "@editorjs/attaches";
// @ts-ignore
import Table from "@editorjs/table";
// @ts-ignore
import Embed from "@editorjs/embed";

export const EditorTools = (
  uploadFn: (_: File) => Promise<{ success: number }>
) => {
  return {
    header: {
      class: Header,
      config: {
        levels: [2, 3, 4],
        defaultLevel: 3,
      },
      inlineToolbar: ["link"],
    },
    list: {
      class: List,
      inlineToolbar: true,
      config: { defaultStyle: "unordered" },
    },
    paragraph: {
      class: Paragraph,
      inlineToolbar: true,
    },
    checklist: {
      class: Checklist,
      inlineToolbar: true,
    },
    attaches: {
      class: AttachesTool,
      config: {
        uploader: {
          uploadByFile(file: File) {
            return uploadFn(file);
          },
        },
      },
    },
    table: {
      class: Table,
      inlineToolbar: true,
      config: {
        rows: 3,
        cols: 2,
      },
    },
    embed: {
      class: Embed,
      config: {
        services: {
          youtube: true,
          coub: true,
          miro: true,
          twitter: true,
          instagram: true,
          facebook: true,
          vimeo: true,
        },
      },
    },
  };
};
