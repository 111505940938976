import React, { useContext, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import { useNavigate, useParams } from "react-router-dom";
import { SupabaseClient } from "@supabase/supabase-js";
import { OutputData } from "@editorjs/editorjs";
import { Form, Button } from "react-bootstrap";
import { AppContext } from "../App";
import {
  Submission,
  getSubmission as getRepoSubmission,
} from "../repo/submissions";
import { EditorTools } from "../lib/editor";

const NewDocumentWorkflow: React.FC<{ supabase: SupabaseClient }> = ({
  supabase,
}) => {
  const { submissionId } = useParams<string>();
  const [title, setTitle] = useState<string>("Untitled");
  const { setError } = useContext(AppContext);

  const [submission, setSubmission] = useState<Submission>();

  const [isSaving, setSaving] = useState<boolean>();
  const navigate = useNavigate();
  const isInstance = React.useRef<EditorJS | null>(null);
  const firstRenderRef = React.useRef(true);

  const getSubmission = async () => {
    const data = await getRepoSubmission(supabase, submissionId as string);
    if (data instanceof Error) {
      setError(data.message);
      return;
    }

    setSubmission(data);
    setTitle(data.title);

    return data;
  };

  const handleFileUpload = async (f: File) => {
    const fname = f.name.replace(/[^a-zA-Z0-9.\s]/g, "_");
    const { error, data } = await supabase.storage
      .from("attachments")
      .upload(`${submissionId}/${fname}`, f, { upsert: true });
    if (error) return Promise.reject({ success: 0 });

    return Promise.resolve({
      success: 1,
      file: { name: f.name, path: data.path },
    });
  };

  const newEditor = React.useCallback(
    (submission: Submission) => {
      const editor = new EditorJS({
        holder: "editor-js",
        tools: EditorTools(handleFileUpload),
        autofocus: true,
        placeholder: "type here...",
        readOnly: submission.state !== "draft",
        onReady: () => {
          isInstance.current = editor;
        },
        data: submission.data as OutputData,
      });
    },
    [isInstance]
  );

  const initEditor = async () => {
    const submission = await getSubmission();
    newEditor(submission as Submission);
  };

  React.useEffect(() => {
    if (!isInstance.current && firstRenderRef.current) {
      firstRenderRef.current = false;

      initEditor();
    }
    return () => {
      isInstance.current && isInstance.current.destroy();
      isInstance.current = null;
    };
  }, [isInstance]);

  const handleSave = async () => {
    setSaving(true);
    if (!isInstance.current) {
      setError("error loading editor");
      return;
    }
    const output = await isInstance.current.save();
    const { error } = await supabase
      .from("submissions")
      .update({ data: output, updated_at: new Date(), title })
      .eq("id", submissionId);
    if (error) {
      return new Error(error.message);
    }

    setSaving(false);
  };

  const handleNext = async () => {
    await handleSave();
    setTimeout(() => {
      navigate(`/workflow/preview/${submissionId}`);
    }, 2000);
  };

  return (
    <>
      <div className="w-75 mx-auto">
        <Form.Group className="mb-3">
          <Form.Control
            size="lg"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            disabled={submission && submission.state !== "draft"}
          />
        </Form.Group>
        <div id="editor-js" className="shadow p-3 mb-5 bg-body rounded"></div>

        <div className="row">
          <div className="col-md-4">
            <Button onClick={handleSave} disabled={isSaving}>
              {isSaving ? "Saving" : "Save"}
            </Button>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button onClick={handleNext} className="float-right">
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewDocumentWorkflow;
