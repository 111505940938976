import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { SupabaseClient, User } from "@supabase/supabase-js";
import { AppContext } from "../App";

interface Prop {
  supabase: SupabaseClient;
}

const Navigation: React.FC<Prop> = ({ supabase }) => {
  const navigate = useNavigate();
  const userAction = (action: "login" | "logout") => {
    navigate(`/${action}`);
  };
  const { user } = useContext(AppContext);

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">doqflow</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/">
              Home
            </Nav.Link>
            {user && user.isAdmin && (
              <>
                <NavDropdown title="Admin" id="basic-nav-dropdown">
                  <NavDropdown.Item
                    onClick={() => navigate("/admin/templates")}
                  >
                    Templates
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/admin/users/manage")}
                  >
                    Users
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/admin/submissions/list")}
                  >
                    Submissions
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
            <NavDropdown title="Account" id="basic-nav-dropdown">
              {user && (
                <NavDropdown.Item onClick={() => navigate("/submissions/my")}>
                  My Submissions
                </NavDropdown.Item>
              )}

              {user && (
                <>
                  <NavDropdown.Divider />

                  <NavDropdown.Item onClick={() => userAction("logout")}>
                    Logout
                  </NavDropdown.Item>
                </>
              )}
              {!user && (
                <>
                  <NavDropdown.Divider />

                  <NavDropdown.Item onClick={() => userAction("login")}>
                    Login
                  </NavDropdown.Item>
                </>
              )}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
