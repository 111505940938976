import React, { useContext, useEffect, useState } from "react";
import {
  Submission,
  getSubmission,
  rejectSubmission,
} from "../../repo/submissions";
import { Link, useParams } from "react-router-dom";
import { SupabaseClient } from "@supabase/supabase-js";
import { AppContext } from "../../App";
import { Container, Spinner, Card, Button, ListGroup } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Editor from "./../editor";
import { OutputData } from "@editorjs/editorjs";
import ListAttachments from "./../listAttachments";
import { MultiSelect, Option } from "react-multi-select-component";
import { getOrgUsers } from "../../repo/user";

const SubmissionAdminView: React.FC<{ supabase: SupabaseClient }> = ({
  supabase,
}) => {
  const { submissionId } = useParams();
  const { user } = useContext(AppContext);

  const [submission, setSubmission] = useState<Submission | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const [selected, setSelected] = React.useState<Option[]>([]);
  const [options, setOptions] = React.useState<Option[]>([
    {
      label: "fg",
      value: "adf",
    },
  ]);

  const { setError } = useContext(AppContext);

  const loadSubmission = async () => {
    const data = await getSubmission(supabase, submissionId as string);
    if (data instanceof Error) {
      setError(data.message);
      return;
    }
    setSubmission(data);
  };

  const loadOrgUsers = async () => {
    if (user) {
      const resp = await getOrgUsers(supabase, user.org);
      if (resp instanceof Error) {
        setError(resp.message);
        return;
      }
      const users = resp.map((u) => {
        return { label: `${u.fullname} - ${u.email}`, value: u.id };
      });
      setOptions(users);
    }
  };

  useEffect(() => {
    setLoading(true);
    loadSubmission();
    loadOrgUsers();
    setLoading(false);
  }, []);

  const handleReject = async () => {
    await rejectSubmission(supabase, submissionId as string).catch((e) =>
      setError("failed to update submission")
    );
  };

  const handleSave = async () => {
    await supabase.rpc("assign_reviewers", {
      submissionid: submissionId,
      reviewers: selected.map((s) => s.value),
    });
  };

  if (!loading && submission) {
    return (
      <Container>
        <Row>
          <Col>
            <h4>{submission.title}</h4>
            <hr />
            <Editor
              templateData={submission.data as OutputData}
              setError={setError}
              saveFn={() => {
                return Promise.resolve(true);
              }}
              uploadFn={(f) => {
                return Promise.resolve({ success: 1 });
              }}
              disableAutoSave={true}
              readonly={true}
            />
          </Col>
          <Col xs ls="2" sm={4} md={3}>
            <Row>
              <h4>Attachments</h4>
              <hr />
              <ListAttachments
                submissionId={submission.id}
                supabase={supabase}
              />
            </Row>
            <Row className="mt-2">
              <Card className="text-center mb-3">
                <Card.Body>
                  <Card.Text>Discarding this submissions is final</Card.Text>
                  <Button
                    className="btn-danger"
                    onClick={handleReject}
                    disabled={submission.state == "rejected"}
                  >
                    Reject
                  </Button>
                </Card.Body>
              </Card>
            </Row>
            <Row>
              <h4>Assign reviewers</h4>
              <MultiSelect
                disabled={submission.state == "rejected"}
                options={options}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
              />
              {selected && (
                <ListGroup className="mt-3">
                  {selected.map((s) => {
                    return (
                      <ListGroup.Item key={s.value}>{s.label}</ListGroup.Item>
                    );
                  })}
                </ListGroup>
              )}
            </Row>
            <Row className="mt-3">
              <Button onClick={handleSave}>Save Changes</Button>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <div className="mx-auto">
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  }
};

export default SubmissionAdminView;
