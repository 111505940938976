import * as React from "react";
import Table from "react-bootstrap/Table";
import RequireAdmin from "../auth/admin";
import { AppContext } from "../../App";
import { SupabaseClient } from "@supabase/supabase-js";
import { deleteTemplate, getOrgTemplates } from "../../repo/templates";

import deleteIcon from "../../assets/icons/delete.png";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const ListTemplates: React.FC<{ supabase: SupabaseClient }> = ({
  supabase,
}) => {
  const navigate = useNavigate();

  const [templates, setTemplates] = React.useState<
    { id: any; title: any; created_at: any }[]
  >([]);

  const { user, setError } = React.useContext(AppContext);
  const handleDelete = async (id: string, idx: number) => {
    const e = await deleteTemplate(supabase, id);
    if (e) {
      setError("error deleting template");
      return;
    }
    console.log(templates);

    setTemplates(templates.filter((_, index) => index !== idx));
  };

  React.useEffect(() => {
    (async () => {
      if (user) {
        const templates = await getOrgTemplates(supabase);
        setTemplates(templates);
      }
    })();
  }, [user]);

  return (
    <RequireAdmin>
      <>
        <Table responsive="sm" bordered hover>
          <thead>
            <tr>
              <th>Title</th>
              <th>Created At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {templates.map((t: any, i: any) => {
              return (
                <tr key={t.id}>
                  <td>
                    {" "}
                    <Link to={`/admin/templates/edit/${t.id}`}>{t.title}</Link>
                  </td>
                  <td>{t.created_at}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      onClick={() => {
                        handleDelete(t.id, i);
                      }}
                    >
                      <img
                        className="img-responsive center-block"
                        src={deleteIcon}
                        alt="delete"
                      />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button
          onClick={() => {
            navigate("/admin/templates/new");
          }}
        >
          New
        </Button>
      </>
    </RequireAdmin>
  );
};

export default ListTemplates;
