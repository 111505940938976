import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const useLocalstore = <T>(
  k: string
): [T | null, Dispatch<SetStateAction<T | null>>] => {
  const [v, setv] = useState<T | null>(() => {
    const v = localStorage.getItem(k);
    return v ? (JSON.parse(v) as T) : null;
  });

  useEffect(() => {
    const s = JSON.stringify(v);
    if (s) localStorage.setItem(k, s);
  }, [k, v]);

  return [v, setv];
};
