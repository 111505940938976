import React, { useContext } from "react";
import { SupabaseClient } from "@supabase/supabase-js";
import { Button } from "react-bootstrap";
import PreviewDocumentSubmission from "./preview_doc_submission";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../App";

interface Props {
  supabase: SupabaseClient;
}

const SubmitSubmission: React.FC<Props> = ({ supabase }) => {
  const { submissionId } = useParams();
  const { setError } = useContext(AppContext);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const { error } = await supabase.rpc("submit_document_submission", {
      submissionid: submissionId as string,
    });
    if (error) {
      setError("Something went wrong!");
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <div className="mx-auto">
        <PreviewDocumentSubmission supabase={supabase} />
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
    </>
  );
};

export default SubmitSubmission;
