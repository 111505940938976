import { AuthError, SupabaseClient } from "@supabase/supabase-js";

export interface AppUser {
  id: string;
  email?: string;
  created_at: string;
  confirmed_at?: string;
  email_confirmed_at?: string;
  phone_confirmed_at?: string;
  last_sign_in_at?: string;
  role?: string;
  isAdmin: boolean;
  org: number;
}

export async function getUser(
  supabase: SupabaseClient
): Promise<AppUser | AuthError | Error> {
  const { data, error } = await supabase.auth.getUser();
  if (error) return error;
  const user = data.user;

  const appUser: AppUser = {
    id: user.id,
    email: user.email,
    created_at: user.created_at,
    role: user.role,
    isAdmin: false,
    org: -1,
  };

  if (user) {
    const { data } = await supabase
      .from("org_members")
      .select(`"org", "isAdmin"`)
      .eq("uid", user.id)
      .limit(1)
      .single();
    if (!data) {
      return new Error("error fetching your data");
    }

    appUser.isAdmin = data.isAdmin;
    appUser.org = data.org;
  }

  return appUser;
}

export async function getOrgUsers(supabase: SupabaseClient, org: number) {
  const { data } = await supabase
    .from("org_members")
    .select(`"uid", "fullname", "email", "isAdmin"`)
    .eq("org", org);
  if (!data) {
    return new Error("error fetching users data");
  }

  return data.map((u) => {
    return {
      id: u.uid,
      isAdmin: u.isAdmin,
      email: u.email,
      fullname: u.fullname,
    };
  });
}
