import { SupabaseClient } from "@supabase/supabase-js";
import { FC, useContext, useEffect, useState } from "react";
import * as React from "react";
import { AppContext } from "../../App";
import { getOrgUsers } from "../../repo/user";
import { Button, Table } from "react-bootstrap";

const ListOrgUsers: FC<{ supabase: SupabaseClient }> = ({ supabase }) => {
  const { user, setError } = useContext(AppContext);
  const [users, setUsers] = useState<
    {
      id: any;
      fullname: any;
      email: any;
      isAdmin: any;
    }[]
  >();

  const loadOrgUsers = async () => {
    if (user) {
      const u = await getOrgUsers(supabase, user.org);
      if (u instanceof Error) {
        setError(u.message);
        return;
      }
      setUsers(u);
    }
  };

  useEffect(() => {
    loadOrgUsers();
  }, []);

  return (
    <Table>
      <thead>
        <tr>
          <th>Email, Fullname</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {users &&
          users.map((u) => {
            return (
              <tr key={u.id}>
                <td>
                  <b>{u.email}</b> {u.fullname}
                  {u.isAdmin && (
                    <span className="badge text-bg-info">Admin</span>
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default ListOrgUsers;
