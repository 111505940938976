import { SupabaseClient } from "@supabase/supabase-js";

export interface SubmissionSummary {
  id: string;
  title: string;
  updated_at: string;
  created_at: string;
  org: number;
  author: string;
  state: string;
}

export const getUserSubmissionsSummary = async (supabase: SupabaseClient) => {
  const { data: userData, error: authError } = await supabase.auth.getUser();
  if (authError) {
    return Promise.reject(authError.message);
  }

  const { data, error } = await supabase
    .from("submissions")
    .select(
      `"id", "title", "updated_at", "created_at", "org", "author", "state"`
    )
    .eq("author", userData.user.id);

  if (error) {
    return [];
  }

  return data;
};

export interface Submission extends SubmissionSummary {
  data: unknown;
}

export async function getSubmission(
  supabase: SupabaseClient,
  submissionId: string
): Promise<Submission | Error> {
  const { data, error } = await supabase
    .from("submissions")
    .select(
      `"id", "title", "updated_at", "created_at", "org", "author", "state", "data"`
    )
    .eq("id", submissionId)
    .limit(1);
  if (error) {
    return new Error(error.message);
  }
  return data.length > 0 ? data[0] : new Error("not found");
}

export async function deleteSubmission(supabase: SupabaseClient, id: string) {
  const { error } = await supabase
    .from("submissions")
    .delete()
    .eq("id", id);
  if (error) return new Error("cannot delete submission");
}

export async function getOrgSubmissions(supabase: SupabaseClient, org: number) {
  const { data, error } = await supabase
    .from("submissions")
    .select(
      `"id", "title", "updated_at", "created_at", "org", "author", "state", "data"`
    )
    .eq("org", org)
    .neq("state", "draft");
  if (error) return new Error("failed to get submissions");

  return data;
}

export async function rejectSubmission(
  supabase: SupabaseClient,
  submissionId: string
) {
  const { error } = await supabase.rpc("admin_reject_submission", {
    submissionid: submissionId,
  });
  if (error) return Promise.reject(error);
}
