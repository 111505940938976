import * as React from "react";
import { Submission, getOrgSubmissions } from "../../repo/submissions";
import { SupabaseClient } from "@supabase/supabase-js";
import { AppContext } from "../../App";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const ListOrgSubmissions: React.FC<{ supabase: SupabaseClient }> = ({
  supabase,
}) => {
  const [submissions, setSubmissions] = React.useState<Submission[]>();
  const [filteredSubmissions, setFilteredSubmissions] = React.useState<
    Submission[]
  >();
  const [filterState, setFilterState] = React.useState<string>();

  const { user, setError } = React.useContext(AppContext);

  const loadSubmissions = async () => {
    if (user) {
      const d = await getOrgSubmissions(supabase, user.org);
      if (d instanceof Error) setError(d.message);
      else {
        setSubmissions(d);
        setFilteredSubmissions(d);
      }
    }
  };
  React.useEffect(() => {
    loadSubmissions();
  }, []);

  const handleStateFilter = (state: string | undefined) => {
    if (state === undefined) {
      setFilteredSubmissions(submissions);
    }

    if (submissions) {
      setFilteredSubmissions(submissions.filter((s) => s.state === state));
    }
    setFilterState(state);
  };

  return (
    <>
      <Row>
        <Col>
          <ListGroup horizontal>
            <ListGroup.Item
              action
              onClick={() => {
                handleStateFilter(undefined);
              }}
              active={filterState === undefined}
            >
              All
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={() => {
                handleStateFilter("awaiting_admin_review");
              }}
              active={filterState === "awaiting_admin_review"}
            >
              Waiting for your action
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={() => {
                handleStateFilter("awaiting_review");
              }}
              active={filterState === "awaiting_review"}
            >
              Draft
            </ListGroup.Item>
            <ListGroup.Item
              action
              onClick={() => {
                handleStateFilter("reviewer_approved");
              }}
              active={filterState === "reviewer_approved"}
            >
              Approved by Reviewer
            </ListGroup.Item>
          </ListGroup>
          <hr />
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>State</th>
                <th>Submitted on</th>
              </tr>
            </thead>
            <tbody>
              {filteredSubmissions &&
                filteredSubmissions.map((s) => {
                  return (
                    <tr key={s.id}>
                      <td>
                        <Link to={`/admin/submissions/manage/${s.id}`}>
                          {s.title}
                        </Link>
                      </td>
                      <td>{s.state}</td>
                      <td>{s.created_at}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default ListOrgSubmissions;
