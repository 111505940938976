import React, { useContext, useEffect, useState } from "react";
import { Submission, getSubmission } from "../repo/submissions";
import { Link, useParams } from "react-router-dom";
import { SupabaseClient } from "@supabase/supabase-js";
import { AppContext } from "../App";
import { Container, Spinner, Card, Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Editor from "./editor";
import { OutputData } from "@editorjs/editorjs";
import ListAttachments from "./listAttachments";

const PreviewDocumentSubmission: React.FC<{ supabase: SupabaseClient }> = ({
  supabase,
}) => {
  const { submissionId } = useParams();

  const [submission, setSubmission] = useState<Submission | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const { setError } = useContext(AppContext);

  const loadSubmission = async () => {
    const data = await getSubmission(supabase, submissionId as string);
    if (data instanceof Error) {
      setError(data.message);
      return;
    }
    setSubmission(data);
  };

  useEffect(() => {
    setLoading(true);
    loadSubmission();
    setLoading(false);
  }, []);

  if (!loading && submission) {
    return (
      <Container>
        <Row>
          <Col>
            <h4>{submission.title}</h4>
            <hr />
            <Editor
              templateData={submission.data as OutputData}
              setError={setError}
              saveFn={() => {
                return Promise.resolve(true);
              }}
              uploadFn={(f) => {
                return Promise.resolve({ success: 1 });
              }}
              disableAutoSave={true}
              readonly={true}
            />
          </Col>
          <Col xs ls="2" sm={4} md={3}>
            <Row>
              <Col>
                <h4>Attachments</h4>
                <hr />
                <ListAttachments
                  submissionId={submission.id}
                  supabase={supabase}
                />
              </Col>
              <Col className="mt-2">
                <Card style={{ width: "18rem" }}>
                  <Card.Body>
                    <Card.Title>Please check</Card.Title>
                    <Card.Text>
                      Please check before you submit, you won't be able to edit
                      until after your review
                    </Card.Text>
                    {submission.state == "draft" && (
                      <Link to={`/workflow/edit/${submissionId}`}>
                        Go back editing
                      </Link>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <div className="mx-auto">
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  }
};

export default PreviewDocumentSubmission;
