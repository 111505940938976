import React, { useContext, useEffect, useState } from "react";
import { SupabaseClient } from "@supabase/supabase-js";
import {
  SubmissionSummary,
  deleteSubmission,
  getUserSubmissionsSummary,
} from "../repo/submissions";
import { Table } from "react-bootstrap";
import { AppContext } from "../App";
import { Link } from "react-router-dom";
import deleteIcon from "../assets/icons/delete.png";

interface Props {
  supabase: SupabaseClient;
}

const ListSubmissions: React.FC<Props> = ({ supabase }) => {
  const [submissions, setSubmissions] = useState<SubmissionSummary[]>([]);
  const { setError } = useContext(AppContext);

  useEffect(() => {
    getUserSubmissionsSummary(supabase)
      .then((s) => {
        setSubmissions(s);
      })
      .catch((e) => {
        setError(e);
      });
  }, []);

  const deleteRow = React.useCallback(
    async (id: string, idx: number) => {
      const error = await deleteSubmission(supabase, id);
      if (error) {
        setError("cannot delete, please contact support");
      } else {
        setSubmissions(submissions.filter((_, index) => index !== idx));
      }
    },
    [submissions]
  );

  return (
    <>
      <Table responsive="sm" bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Created At</th>
            <th>State</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {submissions.map((s: any, i: any) => {
            return (
              <tr key={s.id}>
                <td>
                  {s.state == "draft" ? (
                    <Link to={`/workflow/edit/${s.id}`}>{s.title}</Link>
                  ) : (
                    <Link to={`/workflow/preview/${s.id}`}>{s.title}</Link>
                  )}
                </td>
                <td>{s.updated_at}</td>
                <td>{s.state}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={() => deleteRow(s.id, i)}
                  >
                    <img
                      className="img-responsive center-block"
                      src={deleteIcon}
                      alt="delete"
                    />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default ListSubmissions;
